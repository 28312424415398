<!-- eslint-disable -->
<template>
<v-app>
<div v-if="mount == true">
   <b-alert
      v-model="showBottom"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      auto-hide-delay
      @dismissed="clearNotification"
    >
    {{newMessage}}
    </b-alert>
  <router-view />
</div>
</v-app>
</template>

<script>
/* eslint-disable */
  import axios from 'axios'
  import { initializeApp } from "firebase/app";
  import { getMessaging, getToken, onMessage } from "firebase/messaging";
  export default {
    name: 'App',
    data() {
      return {
        mount: false,
        newMessage:null,
        showBottom:false,
        firebaseConfig : {
          apiKey: "AIzaSyCEHMD9qVp-i9ZnZTJzIj1AH6xJVecMHcM",
          authDomain: "tlmexpress-app.firebaseapp.com",
          projectId: "tlmexpress-app",
          storageBucket: "tlmexpress-app.appspot.com",
          messagingSenderId: "853029737544",
          appId: "1:853029737544:web:fc991414cfffae17ecb47d",
          measurementId: "G-RG3M74XXGZ"
        }
      }
    },
    methods: {
      checkNewMessage() {
        if (localStorage.getItem('newMessage') != null && localStorage.getItem('newMessage') != undefined) {
          this.showBottom = true
        } else {
          this.showBottom = false
        }
        setTimeout(this.checkNewMessage, 2000)
      },
      clearNotification() {
        this.$router.push({name:'AdminRequests'})
        localStorage.removeItem('newMessage')
        this.showBottom = false
      },
      registerToken(token) {
        let auth_token = localStorage.getItem('auth_token')
        let utype = 'admin'
        if (localStorage.getItem('user_type') == 'provider') {
          utype = 'entreprise'
        } else if (localStorage.getItem('user_type') == 'driver') {
          utype = 'driver'
        } else if (localStorage.getItem('user_type') == 'post_center') {
          utype = 'express'
        }
        axios.post('add-new-driver-device', {
          auth_token: auth_token,
          token: token,
          input_type:'add',
          user_type:utype
        })
      },
      firebaseMessaging() {
        const fapp = initializeApp(this.firebaseConfig);
        const messaging = getMessaging(fapp);
        getToken(messaging, {
          vapidKey:
            "BGtsmA4_Oh1-jRATYu3RNDUPn30mog_LB0o1Z7jPfm44yTykKp9KiJEGKKL9qlUOfY4IzcfaIr5RWLsbzwDiNFo",
        })
          .then((currentToken) => {
            if (currentToken) {
              localStorage.setItem('device_token',currentToken)
              this.registerToken(currentToken)
            } else {
              console.log("No registration token available. Request permission to generate one.");
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });

        onMessage(messaging, (payload) => {
          localStorage.setItem('newMessage',payload.notification.title)
          this.newMessage = payload.notification.body
          this.showBottom = true
          const notification = {
            title: payload.notification.title,
            options: {
              body: payload.notification.body
            },
            events: {
              onclick: function () {
              },
              // onerror: function () {
              //     console.log('Custom error event was called');
              // },
              // onclose: function () {
              //     console.log('Custom close event was called');
              // },
              // onshow: function () {
              //     console.log('Custom show event was called');
              // }
            }
          }
          this.$notification.show(notification.title, notification.options, notification.events)
        });
      },
    },
    mounted() {
      // navigator.serviceWorker.addEventListener('message', event => {
      //   console.log(event.data.msg, event.data.url);
      // });
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      let auth_token = localStorage.getItem('auth_token')
      if (auth_token != null && auth_token != undefined) {
        axios.post('login', {
          auth_token: auth_token
        }).then(response => {
          if (response.data.status == 'success') {
            let type = response.data.data.type
            if (type == 'provider')
              this.$router.push({name:'Dashboard'})
            else if (type == 'depot')
              this.$router.push({name:'DepotDashboard'})
            else if (type == 'driver')
              this.$router.push({name:'DriverDashboard'})
            else if (type == 'post_center')
              this.$router.push({name:'CenterDashboard'})
            else if (type == 'admin')
              this.$router.push({name:'AdminDashboard'})
            this.mount = true
          }
          else {
            if (this.$router.history._startLocation != '/contact-us' && this.$router.history._startLocation != '/about-us' && !this.$router.history._startLocation.includes('/tracker')) this.$router.push({name:'Home'})
            this.mount = true
          }
        }).catch(err => {
          if (this.$router.history._startLocation != '/contact-us' && this.$router.history._startLocation != '/about-us' && !this.$router.history._startLocation.includes('/tracker')) this.$router.push({name:'Home'})
          this.mount = true
        })
      } else {
        if (this.$router.history._startLocation != '/contact-us' && this.$router.history._startLocation != '/about-us' && !this.$router.history._startLocation.includes('/tracker')) this.$router.push({name:'Home'})
        this.mount = true
      }
      this.firebaseMessaging()
      this.checkNewMessage()
    }
  }
</script>
<style>
body {
    background-color:#0b2963;
}
.shown-sm-and-down {
  display: none !important;
}
@media (max-width:800px) {
  .shown-sm-and-down {
    display: block !important;
    margin-top:-5px !important;
  }
}
</style>