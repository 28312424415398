/* esline-disable */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/dashboard/Home'),
    },
    {
      path: '/about-us',
      name: 'AboutUS',
      component: () => import('@/views/dashboard/AboutUs'),
    },
    {
      path: '/tracker',
      name: 'Tracker',
      component: () => import('@/views/dashboard/Tracker'),
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: () => import('@/views/dashboard/ContactUs'),
    },
    {
      path: '/clear-token',
      name: 'ClearToken',
      component: () => import('@/views/dashboard/ClearToken'),
    },
    {
      path: '/dash',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'CenterDashboard',
          path: '/center-dashboard',
          component: () => import('@/views/dashboard/CenterDashboard'),
        },
        {
          name: 'CenterPayments',
          path: '/center-payments',
          component: () => import('@/views/dashboard/CenterPayments'),
        },
        {
          name: 'CreateDelivery',
          path: '/center-providers',
          component: () => import('@/views/dashboard/pages/CreateDelivery'),
        },
        {
          name: 'AdminDashboard',
          path: '/admin-dashboard',
          component: () => import('@/views/dashboard/AdminDashboard'),
        },
        {
          name: 'AdminProviders',
          path: '/admin-providers',
          component: () => import('@/views/dashboard/AdminProviders'),
        },
        {
          name: 'AdminGroupage',
          path: '/admin-groupage',
          component: () => import('@/views/dashboard/AdminGroupage'),
        },
        {
          name: 'AdminExpressPayments',
          path: '/admin-express-payments',
          component: () => import('@/views/dashboard/AdminExpressPayments'),
        },
        {
          name: 'AdminRequests',
          path: '/admin-requests',
          component: () => import('@/views/dashboard/AdminRequests'),
        },
        {
          name: 'AdminShippings',
          path: '/admin-shippings',
          component: () => import('@/views/dashboard/AdminShippings'),
        },
        {
          name: 'AdminLogistics',
          path: '/admin-logistics',
          component: () => import('@/views/dashboard/AdminLogistics'),
        },
        {
          name: 'AdminSOS',
          path: '/admin-sos-requests',
          component: () => import('@/views/dashboard/AdminSOS'),
        },
        {
          name: 'AdminPostCenter',
          path: '/admin-post-centers',
          component: () => import('@/views/dashboard/AdminPostCenters'),
        },
        {
          name: 'AdminDrivers',
          path: '/admin-drivers',
          component: () => import('@/views/dashboard/AdminDrivers'),
        },
        {
          name: 'AdminPrices',
          path: '/admin-prices',
          component: () => import('@/views/dashboard/AdminPrices'),
        },
        {
          name: 'AdminMessages',
          path: '/admin-messages',
          component: () => import('@/views/dashboard/AdminMessages'),
        },
        {
          name: 'PostCenter',
          path: '/post-centers',
          component: () => import('@/views/dashboard/PostCenters'),
        },
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'DriverDashboard',
          path: '/driver-dashboard',
          component: () => import('@/views/dashboard/DriverDashboard'),
        },
        {
          name: 'DepotDashboard',
          path: '/depot-dashboard',
          component: () => import('@/views/dashboard/DepotDashboard'),
        },
        {
          name: 'DepotDrivers',
          path: '/depot-drivers',
          component: () => import('@/views/dashboard/DepotDrivers'),
        },
        {
          name: 'DepotDelivery',
          path: '/depot-delivery',
          component: () => import('@/views/dashboard/DepotDelivery'),
        },
        {
          name: 'DeliveryManDashboard',
          path: '/delivery-man-dashboard',
          component: () => import('@/views/dashboard/DeliveryManDashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: '/pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'My Profile',
          path: '/my-profile',
          component: () => import('@/views/dashboard/pages/Profile'),
        },
        {
          name: 'Notifications',
          path: '/components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: '/components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: '/components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: '/tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: '/maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: '/upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],
    },
  ],
})
