// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
/* eslint-disable */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueGoodTablePlugin from 'vue-good-table'
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'
import Timeline from 'timeline-vuejs'
import 'vue-good-table/dist/vue-good-table.css'
import Clipboard from 'v-clipboard'
import Multiselect from 'vue-multiselect'
import VueNativeNotification from 'vue-native-notification'
import VueQrcodeReader from "vue-qrcode-reader";


Vue.component('multiselect', Multiselect)
Vue.use(VueQrcodeReader);
Vue.use(VueNativeNotification, {requestOnNotify: true})
Vue.use(Clipboard)
Vue.use(Timeline)
Vue.use(VueGoodTablePlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
