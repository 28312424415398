import { render, staticRenderFns } from "./MaterialStatsCard.vue?vue&type=template&id=1a6b3c4f"
import script from "./MaterialStatsCard.vue?vue&type=script&lang=js"
export * from "./MaterialStatsCard.vue?vue&type=script&lang=js"
import style0 from "./MaterialStatsCard.vue?vue&type=style&index=0&id=1a6b3c4f&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports